import * as core from 'cw-ui-core';
import { getUrl } from './ApiService';

const CLIENT_ID = 'productinsightsInt';
const SCOPE = [
    'offline_access',
    'openid',
    'profile',
    'email',
    'roles',
    'approles',
    'api.products',
    'api.partners.crosstenant',
    'api.productinstances.crosstenant',
    'api.users.crosstenant',
    'api.billing.crosstenant',
    'api.centralservices.crosstenant',
    'api.sts.admin.crosstenant',
    'api.remote.login.crosstenant',
    'api.users',
    'api.provisioning.crosstenant',
    'api.connectedapps.crosstenant'
];

export function init() {
    const apiUrl = getUrl();
    addCookieAuthToRequests(apiUrl);
    initOidcAuthCodeProxy(apiUrl);
}

/**
 * Ensure all requests, including libraries such as oidc-client-js, add withCredentials to its XMLHttpRequests.
 * This allows the cookie auth to be initialized and then used on subsequent calls.
 * TODO: Retrigger signin on 401 response from APIs (maybe the cookie expired or was removed)
 */
function addCookieAuthToRequests(apiUrl) {
    (function (open) {
        XMLHttpRequest.prototype.open = function (method, url, async = true) {
            this.requestUrl = url;
            this.requestMethod = method;
            return open.call(this, method, url, async);
        };
    })(XMLHttpRequest.prototype.open);
    (function (send) {
        XMLHttpRequest.prototype.send = function (data) {
            if (this.requestUrl && this.requestUrl.startsWith(apiUrl)) {
                this.withCredentials = true;
            }
            send.call(this, data);
        };
    })(XMLHttpRequest.prototype.send);
}

/**
 * Initializes the special settings used in order to authenticate with CW SSO via OIDC, but then use cookie auth for API calls.
 * No access tokens should be provided to the client.
 */
function initOidcAuthCodeProxy(apiUrl) {
    const port = window.location.port ? `:${window.location.port}` : '';
    const currentUri = core.removeTrailingSlash(`${window.location.protocol}//${window.location.hostname}${port}`);

    const oidcUserManagerConfig = {
        client_id: CLIENT_ID,
        redirect_uri: `${currentUri}/signin-oidc`,
        response_type: 'code',
        scope: SCOPE.join(' '),
        post_logout_redirect_uri: currentUri,
        automaticSilentRenew: false, //access tokens not exposed, nothing to renew
        revokeAccessTokenOnSignout: true, //this will trigger the revocation endpoint
        authority: apiUrl,
        metadataUrl: `${apiUrl}/auth/metadata`,
    };
    core.initializeOidc(oidcUserManagerConfig);
}
